@import 'config';

.footer {
  display: flex;
  background-color: $site-footer-bg-colour;
  padding: 0 30px 30px 30px;
  justify-content: space-between;
}

.footer-nav {
  padding-top: 40px;
}

.footer-nav__header {
  font-family: $site-font-header;
  font-weight: 600;
  color: $site-font-colour-light;
  margin-bottom: 16px;
  white-space: nowrap;
}

.footer-nav__items__item {
  margin-bottom: 20px;
}

.footer-nav__items__item__link {
  display: inline;
  font-family: $site-font-main;
  color: $site-link-light-colour;
  font-size: $site-link-font-size;
  font-weight: 300;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  line-height: inherit;
}

@media (max-width: 780px) {
  .footer {
    flex-wrap: wrap;
  }

  .footer-nav {
    flex-basis: 50%;
  }
}

@media (max-width: 530px) {
  .footer {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .footer-nav {
    flex-basis: 100%;
  }
}