@import 'config';

.site-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 100%;
  font-family: $site-font-main;
  background: $site-banner-bg-colour;
  font-size: 18px;
  font-style: italic;
  text-align: center;
  padding: 10px;
  margin-top: 16px;
}

@media (max-width: 780px) {
  .site-banner {
    order: 4;
    margin-top: 4px;
  }
}