@import 'config';

.cms__slides {
  display: flex;
  flex-direction: column;

  &__add {
    padding: 10px 20px;
    color: $button-text-color;
    background: $button-background-color;
    border: 1px solid $button-border-color;
    border-radius: 2px;
    margin-bottom: 10px;
    min-width: 100px;
    transition: border 0.2s ease-in-out;
    outline: none;
    justify-self: flex-end;

    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }

    &:not(:disabled):hover {
      border-color: $button-border-hover-color;
    }

    &:not(:disabled):focus {
      border-color: $button-border-hover-color;
    }
  }

  &__slide {
    border: 1px solid $button-border-color;
    margin-bottom: 10px;
    padding: 10px;

    &__content {

    }

    &__options {
      display: flex;
      justify-content: space-between;

      &__remove, &__up, &__down {
        padding: 10px 20px;
        color: $button-text-color;
        background: $button-background-color;
        border: 1px solid $button-border-color;
        border-radius: 2px;
        margin-right: 4px;
        min-width: 100px;
        transition: border 0.2s ease-in-out;
        outline: none;

        &:disabled {
          opacity: 0.2;
          cursor: not-allowed;
        }

        &:not(:disabled):hover {
          border-color: $button-border-hover-color;
        }

        &:not(:disabled):focus {
          border-color: $button-border-hover-color;
        }
      }

      &__remove {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
}