@import '../config';
@import '../cta';

.product-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;

  &__breadcrumb {
    flex-basis: 100%;
    border-bottom: 1px solid $colour-maroon;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  &__images {
    display: flex;
    justify-content: flex-start;
    width: 60%;

    &__preview {
      display: block;
      width: 92%;
    }
  }

  &__info {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    box-sizing: border-box;
    flex-basis: 25%;
    flex-direction: column;

    &__header {
      font-size: 20px;
      margin-bottom: 10px;
    }

    &__shop {
      &__price {
        font-size: 18px;
        font-style: italic;
        display: block;
        margin-bottom: 20px;
      }

      &__bag {
        @include cta_maroon_solid;
        margin-bottom: 10px;
      }

      &__bag--remove {
        @include cta_turquoise_outline;
        margin-bottom: 10px;
        width: 100%;
      }

      &__bag--checkout {
        @include cta_turquoise_solid;
        margin-bottom: 10px;
      }

      &__save {
        @include cta_turquoise_outline;
        width: 100%;
      }

      &__whatsapp {
        @include cta_turquoise_outline;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 48px;
        max-height: 54px;
        margin-top: 10px;

        &::before {
          content: "";
          background: url(/images/whatsapp_logo_with_effect.svg) no-repeat;
          width: 48px;
          height: 48px;
          vertical-align: middle;
          background-size: contain;
        }
      }
    }

    &__details {
      margin-top: 30px;

      p {
        margin-top: 15px;
      }
    }
  }
}

@media (max-width: 780px) {
  .product-page {
    flex-direction: column;

    &__images {
      width: 100%;

      &__preview {
        display: block;
        width: 100%;
      }
    }

    &__info {
      &__header {
        margin-top: 10px;
      }
    }
  }
}