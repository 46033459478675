
.quick-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  min-height: 20px;
}

@media (max-width: 780px) {
  .quick-links .picture-nav__item {
    flex-basis: 49%;
    margin: 0 0 0 0.50%;
  }
}

@media (max-width: 430px) {
  .quick-links .picture-nav__item {
    flex-basis: 100%;
    margin: 0;
  }
}