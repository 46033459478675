@import '../config';

.not-found-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  &__header {
    font-family: $site-font-header;
    font-size: 30px;
    margin: 50px 0;
    align-self: center;
  }

  &__info {
    margin-bottom: 50px;
    align-self: center;
    font-size: 18px;
  }
}