@import 'config';

.admin {
  display: flex;
  justify-content: center;
  align-content: center;
  font-family: Arial, serif;
  font-size: 14px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 1000;

  &__content {
    display: flex;
    flex-direction: column;
    width: 350px;
    min-height: 400px;
    justify-self: center;
    align-self: center;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding: 20px;
    background: $model-background-color;
    color: $model-text-color;
  }

  &__logo-container {
    width: 50%;
  }

  &__logo {
    width: 100%;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  input {
    border: none;
    background: #fff;
    color: $list-text-color;
    font-family: inherit;
    margin: 10px 4px;
    padding: 4px;
    outline: 1px solid $button-border-color;
    outline-offset: 2px;
    width: 97%;

    &:focus {
      outline: 1px solid $button-border-hover-color;
      outline-offset: 2px;
    }
  }

  button {
    padding: 10px 20px;
    color: $button-text-color;
    background: $button-background-color;
    border: 1px solid $button-border-color;
    border-radius: 2px;
    margin: 10px 0px;
    min-width: 100px;
    transition: border 0.2s ease-in-out;
    outline: none;

    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }

    &:not(:disabled):hover {
      border-color: $button-border-hover-color;
    }

    &:not(:disabled):focus {
      border-color: $button-border-hover-color;
    }
  }
  
  a {
    cursor: pointer;
    text-decoration: underline;
  }

  a:hover {
    color: $button-border-hover-color;
  }
}