@import 'config';

@mixin cta {
  display: inline-block;
  font-family: $site-font-main;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  padding: 13px 0;
  background: transparent;
  box-sizing: border-box;
  border-width: 2px;
  border-style: outset;
  border-image-slice: 14;
  border-image-width: 20px;
  @media (-webkit-min-device-pixel-ratio: 2.5) {
    border-image-width: 10px;
  }
  border-image-outset: 0px;
  border-image-repeat: stretch stretch;
  outline: none;
  text-decoration: none;
  cursor: pointer;
  margin: 0;
}

@mixin cta_white_outline {
  @include cta;
  padding: 10px 0 13px 0;
  color: $site-font-colour-light;
  border-image-source: url(/images/cta_outline_white.svg);
}

@mixin cta_turquoise_outline {
  @include cta;
  color: $site-font-colour-dark;
  border-image-source: url(/images/cta_outline_turquoise.svg);
}

@mixin cta_maroon_outline {
  @include cta;
  color: $site-font-colour-dark;
  border-image-source: url(/images/cta_outline_maroon.svg);
  z-index: 0;
}

@mixin cta_maroon_solid {
  @include cta_maroon_outline;
  color: $site-font-colour-light;
  width: 100%;
  font-family: $site-font-logo;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  position: relative;
  &:before {
    display: block;
    position: absolute;
    content: "";
    top: 2px;
    left: 4px;
    bottom: 1px;
    right: 0;
    background: $colour-maroon;
    z-index: -1;
  }
}

@mixin cta_turquoise_solid {
  @include cta_turquoise_outline;
  color: $site-font-colour-dark;
  width: 100%;
  font-family: $site-font-logo;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  position: relative;
  &:before {
    display: block;
    position: absolute;
    content: "";
    top: 2px;
    left: 4px;
    bottom: 1px;
    right: 0;
    background: $site-banner-bg-colour;
    z-index: -1;
  }
}