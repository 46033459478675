@import '../config';
@import '../cta';

.wishlist-page {
  display: flex;
  width: 100%;
  flex-direction: column;

  &__options {
    display: flex;
    justify-content: center;
    padding: 20px;

    &__continue {
      @include cta_turquoise_outline;
      width: 250px;
    }
  }

  &__items {
    display: flex;
    background: $colour-light-grey;
    padding: 50px 20px 10px 20px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    &__empty {
      flex-basis: 100%;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      padding: 50px 0 90px 0;
    }

    &__item {
      display: flex;
      flex-basis: 20%;
      min-width: 240px;
      margin: 0 0.75% 40px 0.75%;
      padding: 20px;
      background: #fff;
      flex-direction: column;
      box-shadow: 2px 2px 8px 1px rgba(0,0,0,0.1);

      &__link {
        text-decoration: none;
        margin-top: 20px;
        display: block;
      }

      &__remove {
        background: url(/images/cross.svg) no-repeat center center;
        background-size: 50px 50px;
        width: 28px;
        height: 28px;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: none;
        align-self: flex-end;
      }

      &__image-container {
        max-height: 280px;
      }

      &__image {
        width: 100%;
        object-fit: cover;
      }

      &__info {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 10px 10px;

        &__title, &__price {
          text-decoration: none;
          font-family: $site-font-main;
          font-style: normal;
          font-weight: 400;
          font-size: $site-link-font-size;
          color: $site-link-dark-colour;
          padding: 6px 0;
        }

        &__price {
          font-weight: bold;
        }
      }

      &__buy {
        @include cta_maroon_solid;
        width: 100%;
      }

      &__buy--checkout {
        @include cta_turquoise_solid;
        width: 100%;
      }
    }
  }
}

@media (max-width: 780px) {
  .wishlist-page {
    &__items {
      padding: 20px 10px;
      flex-direction: column;
      flex-wrap: nowrap;

      &__empty {
        padding: 50px;
      }

      &__item {
        flex-direction: row;
        flex-basis: unset;
        flex-wrap: wrap;
        padding: 20px 20px;
        justify-content: space-between;
        margin: 0 0 20px;

        &__link {
          order: 1;
          flex-basis: $normal-column-width;
          margin-top: 0;
        }

        &__image-container {

        }

        &__image {

        }

        &__info {
          order: 2;
          margin-right: auto;
          justify-content: center;
          flex-basis: 60%;

          &__title, &__price {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
          }
        }

        &__remove {
          order: 3;
          flex-basis: $small-column-width;
          background: url(/images/cross.svg) no-repeat right top;
          background-size: 30px 30px;
          height: 28px;
          width: 28px;
          border: none;
          padding: 0;
          cursor: pointer;
          outline: none;
          align-self: flex-start;
        }

        &__buy {
          order: 4;
          flex-basis: 100%;
          margin-top: 20px;
        }
      }
    }
  }
}