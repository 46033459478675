@import 'config';

.image-preview {
  display: flex;
  flex-direction: column;

  &__selected {

    &__image {
      display: block;
      width: 100%;
    }
  }

  &__thumbnails {
    position: relative;

    &--hidden {
      display: none;
    }

    &__back, &__next {
      position: absolute;
      right: -2px;
      top: 40%;
      bottom: 40%;
      width: 30px;
      border: none;
      background: $colour-light-grey;
      opacity: 0.8;
      outline: none;

      &:after {
        display: block;
        content: "";
        background: url(/images/arrow.svg) no-repeat -2px center;
        background-size: cover;
        width: 20px;
        height: 20px;
      }
    }

    &__back {
      left: -1px;
      right: unset;

      &:after {
        transform: scaleX(-1);
        background: url(/images/arrow.svg) no-repeat center center;
      }
    }

    &__items {
      display: flex;
      overflow-x: scroll;
      padding: 10px;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &::-webkit-scrollbar {
        display: none;
      }

      &__item {
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        width: 150px;
        margin-right: 8px;
        cursor: pointer;
        padding: 2px;
        border: 1px solid transparent;

        &:last-of-type {
          margin-right: 0;
        }

        &--selected {
          border: 1px solid $colour-maroon;
        }

        &__image {
          width: 100%;
          display: block;
          align-self: center;
        }
      }
    }
  }

}

@media (max-width: 780px) {
  .image-preview {
    &__selected {
      display: none;
    }

    &__thumbnails {

      &--hidden {
        display: inherit;
      }

      &__back, &__next {
        top: 45%;
        bottom: 45%;
      }

      &__items {

        &__item {
          width: 100%;

          &--selected {
            border: none;
          }
        }
      }
    }
  }
}