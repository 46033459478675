@import 'config';

.trending {
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  &__header {
    font-family: $site-font-header;
    font-weight: 600;
    color: $site-font-colour-dark;
    font-size: 20px;
    margin: 30px 0;
    text-align: center;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;

    &__products {
      width: 100%;
    }

    &__back {
      display: none;
    }

    &__next {
      display: none;
    }
  }
}

@media (max-width: 780px) {
  .trending {
    &__content {
      &__products {
        width: 240px;
      }

      &__back {
        display: block;
        visibility: hidden;
        transform: scaleX(-1);
        background: url(/images/arrow.svg) no-repeat center center;
        background-size: cover;
        width: 50px;
        height: 50px;
        border: none;
        outline: none;
        padding: 0;
        margin-bottom: 90px;

        &--visible {
          visibility: visible;
        }
      }

      &__next {
        display: block;
        visibility: hidden;
        background: url(/images/arrow.svg) no-repeat -2px center;
        background-size: cover;
        width: 50px;
        height: 50px;
        border: none;
        outline: none;
        padding: 0;
        margin-bottom: 90px;

        &--visible {
          visibility: visible;
        }
      }
    }
  }
}