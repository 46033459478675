
$colour-turquoise: #afd8d2;
$colour-maroon: #432d33;
$colour-light-grey: #f2f2f2;
$colour-error: rgb(253, 185, 44);

$site-font-main: minion-pro, serif;
$site-font-header: trajan-pro-3, serif;
$site-font-logo: trajan-pro-3, serif;
$site-font-colour-dark: $colour-maroon;
$site-font-colour-light: #fff;
$site-font-size: 15px;
$site-link-dark-colour: $colour-maroon;
$site-link-light-colour: #fff;
$site-link-light-colour: #fff;
$site-link-font-size: 15px;
$site-bg-colour: #fff;
$site-banner-bg-colour: $colour-turquoise;
$site-footer-bg-colour: #432d33;
$site-count-bg-colour: $colour-turquoise;

$normal-column-width: 20%;
$small-column-width: 12%;