@import 'config';

.logo {
  margin-top: 20px;

  &__link {
    display: flex;
    align-items: flex-end;
    height: 100px;
    background-image: url(/images/logo.svg);
    background-repeat: no-repeat;
    background-position: center top;
    text-decoration: none;
    outline: none;

    &__text {
      font-family: $site-font-logo;
      font-weight: 700;
      font-style: normal;
      text-transform: uppercase;
      font-size: 22px;
      text-decoration: none;
      color: $site-font-colour-dark;
      white-space: nowrap;
      margin-bottom: 24px;
    }

    &--selected {

    }
  }
}

@media (max-width: 780px) {
  .logo {
    order: 1;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;

    &__link {
      height: 60px;

      &__text {
        font-size: 16px;
        margin-bottom: 16px;
      }
    }
  }
}

@media (max-width: 430px) {
  .logo {

    &__link {
      height: 50px;

      &__text {
        font-size: 12px;
        margin-bottom: 12px;
      }
    }
  }
}