@import 'config';

.shop-nav {
  display: flex;
  flex: 1;
  margin-left: auto;
  align-self: flex-end;
  justify-content: flex-end;
  margin-bottom: 22px;

  &__items {
    display: flex;
    padding-right: 14px;

    &__item {
      &__link {
        position: relative;
        width: 30px;
        height: 30px;
        display: block;
        text-decoration: none;
        transition: background-image 0.2s ease-in-out;

        &--wishlist, &--bag {
          &__count {
            font-family: $site-font-main;
            color: $site-link-dark-colour;
            position: absolute;
            display: block;
            border-radius: 100%;
            background-color: $site-count-bg-colour;
            width: 20px;
            height: 20px;
            line-height: 20px;
            bottom: -5px;
            right: -5px;
            font-size: 10px;
            text-align: center;
          }
        }

        &--bag {
          background-image: url(/images/shopping_bag_with_effect.svg);
          background-repeat: no-repeat;

          &--selected {
            background-image: url(/images/shopping_bag_with_effect_full.svg);
          }
        }

        &--wishlist {
          background-image: url(/images/heart_with_effect.svg);
          background-repeat: no-repeat;
          margin-right: 10px;
          margin-top: 2px;

          &--selected {
            background-image: url(/images/heart_with_effect_full.svg);
          }

          &__count {
            bottom: -3px;
          }
        }
      }
    }
  }
}

@media (max-width: 780px) {
  .shop-nav {
    order: 2;
    margin-left: unset;
    margin-bottom: unset;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    &__items {

      &__item {
        position: absolute;

        &:first-of-type {
          top: 26px;
          left: 10px;
        }

        &:last-of-type {
          top: 26px;
          right: 10px;
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .shop-nav {

    &__items {

      &__item {

        &:first-of-type {
          top: 16px;
        }

        &:last-of-type {
          top: 16px;
        }
      }
    }
  }
}