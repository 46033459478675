@import '../config';
@import '../cta';

.my-bag-page {
  display: flex;
  width: 100%;
  flex-direction: column;

  &__complete {
    margin: 50px 0;
    font-weight: bold;
    font-size: 18px;
    text-align: center;

    &__header {
      margin-bottom: 50px;
    }

    &__message {
      padding: 10px;
    }
  }

  &__options {
    display: flex;
    justify-content: space-around;
    padding: 20px;

    &__continue {
      @include cta_turquoise_outline;
      width: 250px;
      align-self: flex-start;
    }

    &__checkout {
      width: 300px;

      &--card {
        width: 20%;
        min-width: 300px;
        z-index: 500;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        padding: 20px;
        overflow-y: scroll;
        box-shadow: -4px 0px 5px -2px rgba(0,0,0,0.2);
        border-right: 1px solid $colour-light-grey;
      }
    }

  }

  &__checkout {
    position: relative;

    &__blocker {
      display: flex;
      justify-content: center;
      align-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($colour-light-grey, 0.7);
      z-index: 100;
    }
  }

  &__items {
    display: flex;
    background: $colour-light-grey;
    padding: 50px 15% 50px 15%;
    flex-direction: column;
    justify-content: space-between;

    &__footer {
      display: flex;
      justify-content: space-between;
    }

    &__offer-code {
      display: flex;
      align-items: center;
      margin-right: 10px;

      &__label {
        font-weight: bold;
        font-size: $site-font-size;
        margin-right: 4px;
        white-space: nowrap;
      }

      &__input {
        outline: none;
        font-family: $site-font-main;
        font-size: $site-font-size;
        color: #000;
        width: 100%;
        max-width: 200px;
        border: none;
        padding: 4px;
      }
    }

    &__empty {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      padding: 50px 0;
    }

    &__headers {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $colour-maroon;
      padding-bottom: 10px;
      margin-bottom: 10px;

      &__remove {
        flex-basis: $small-column-width;
        font-size: 18px;
        text-align: center;
      }

      &__image, &__info, &__price {
        flex-basis: $normal-column-width;
        font-size: 18px;
      }
    }

    &__item {
      display: flex;
      padding: 20px 0 20px 20px;
      background: #fff;
      margin-bottom: 20px;
      box-shadow: 2px 2px 8px 1px rgba(0,0,0,0.1);
      justify-content: space-between;
      align-content: flex-start;

      &__link {
        flex-basis: $normal-column-width;
        text-decoration: none;
      }

      &__remove {
        display: flex;
        flex-basis: $small-column-width;
        justify-content: center;

        &__button {
          background: url(/images/cross.svg) no-repeat 0 0;
          background-size: 30px 30px;
          height: 28px;
          width: 28px;
          border: none;
          padding: 0;
          cursor: pointer;
          outline: none;
        }
      }

      &__image {
        display: block;
        width: 100%;
      }

      &__info {
        display: flex;
        flex-basis: 53%;

        &__title, &__price {
          margin-top: 4px;
          flex-basis: 57%;
          text-decoration: none;
          font-family: $site-font-main;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          color: $site-link-dark-colour;
        }

        &__price {
          flex-basis: $normal-column-width;
          font-weight: bold;
        }
      }
    }

    &__summary {
      display: flex;
      flex-direction: column;
    }

    &__discount {
      font-weight: bold;
      margin-right: 10px;
      align-self: flex-end;
      margin-bottom: 10px;
      white-space: nowrap;
    }

    &__total {
      font-weight: bold;
      font-size: 18px;
      margin-right: 10px;
      align-self: flex-end;
      white-space: nowrap;
    }
  }
}

@media (max-width: 780px) {
  .my-bag-page {

    &__items {
      padding: 20px 10px;

      &__headers {
        display: none;
      }

      &__item {

        &__info {
          display: flex;
          flex-basis: 62%;
          flex-direction: column;
          justify-content: center;

          &__title, &__price {
            flex-basis: unset;
          }

          &__title {
            margin-bottom: 10px;
          }
        }
      }
    }

    &__options {
      flex-direction: column-reverse;
      align-items: center;

      &__continue {
        margin-bottom: 20px;
        width: 300px;
        align-self: unset;
      }

      &__checkout {
        margin-bottom: 20px;

        &--card {
          left: 0;
          width: unset;
          box-shadow: unset;
          border-right: unset;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .my-bag-page {
    &__items {
      &__footer {
        flex-direction: column;
        align-items: center;
      }

      &__offer-code {
        margin-bottom: 20px;
        flex-direction: column;
        margin-right: 0;

        &__label {
          margin-bottom: 4px;
        }

        &__input {
          text-align: center;
        }
      }

      &__summary {
        align-items: center;
      }

      &__discount {
        align-self: unset;
        margin-right: 0;
      }

      &__total {
        align-self: unset;
        margin-right: 0;
      }
    }
  }
}