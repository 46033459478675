@import 'config';

.main-nav {
  flex: 1;
  margin-right: auto;
  align-self: flex-end;
  margin-bottom: 18px;

  &__items {
    display: flex;
    padding: 10px;

    &__item {
      padding: 0 10px;
      border-right: 1px solid $site-link-dark-colour;

      &:last-of-type {
        border-right: none;
      }

      &__link {
        text-decoration: none;
        font-family: $site-font-main;
        font-style: normal;
        font-weight: 400;
        font-size: $site-link-font-size;
        color: $site-link-dark-colour;
        border-bottom: 2px solid transparent;
        transition: border-bottom 0.2s ease-in-out;
        white-space: nowrap;

        &--selected {
          font-weight: bold;
          border-bottom-color: $site-link-dark-colour;
        }
      }
    }
  }
}

@media (max-width: 780px) {
  .main-nav {
    flex-basis: 100%;
    order: 3;
    border-top: 1px solid $colour-maroon;
    border-bottom: 1px solid $colour-maroon;
    margin-bottom: unset;

    &__items {
      justify-content: center;

      &__item {
        padding: 4px 30px;
      }
    }
  }
}