@import 'config';

.breadcrumb {
  display: flex;
  align-content: center;
  border-bottom: 1px solid $colour-maroon;
  padding-bottom: 10px;

  &__item {
    padding: 4px;

    &:last-of-type .breadcrumb__item__link {
      color: $colour-turquoise;
    }

    &__link {
      font-family: $site-font-main;
      font-style: normal;
      font-weight: 400;
      font-size: $site-link-font-size;
      color: $site-link-dark-colour;
      text-transform: capitalize;
      text-decoration: none;
    }
  }

  &__separator {
    display: block;
    width: 22px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: url(/images/arrow.svg);
  }
}