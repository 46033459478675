@import 'config';
@import 'cta';

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  &__link {
    text-decoration: none;
  }

  &__image {
    width: 100%;
    min-height: 250px;
    object-fit: cover;
  }

  &__header {
    font-family: $site-font-header;
    font-weight: 600;
    color: $site-font-colour-dark;
    font-size: 20px;
    margin-top: 30px;
    text-align: center;
  }

  &__sub-header {
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
  }

  &__button {
    @include cta_maroon_outline;
    margin-top: 20px;
    width: 250px;
  }
}