@import 'config';

.picture-nav {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  &__item {
    flex-basis: (100% / 4) - 0.5%;
    margin: 0 0 0 0.25%;

    &__link {
      text-decoration: none;
    }

    &--no-space {
      flex-basis: (100% / 4);
      line-height: 0;
      margin: 0;
    }

    &__image {
      width: 100%;
      max-height: 280px;
    }

    &__info {
      display: flex;
      padding: 20px 0;
      justify-content: center;
      align-content: center;

      &__title {
        text-decoration: none;
        font-family: $site-font-main;
        font-style: normal;
        font-weight: 400;
        font-size: $site-link-font-size;
        color: $site-link-dark-colour;

        &:after {
          display: inline-block;
          content: "";
          width: 16px;
          height: 10px;
          background-repeat: no-repeat;
          background-position: center right;
          background-image: url(/images/arrow.svg);
          background-size: 16px 16px;
        }
      }
    }
  }
}