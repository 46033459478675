@import 'config';

.cms__edit__content__field-container {
  &__list-group {
    display: flex;
  }

  &__list {
    display: flex;
    width: 100%;
    border: none;
    position: relative;
    background: #fff;
    font-family: inherit;
    margin: 4px;
    padding: 4px;
    color: $list-text-color;
    outline: 1px solid $button-border-color;
    outline-offset: 2px;

    &--error {
      outline: 1px solid $error-color !important;
    }

    &--delete {
      width: 100%;
    }

    &-delete-button {
      width: 25px !important;
      height: 25px !important;
      margin: 3px;
      padding: 1px !important;
    }

    &__selected {
      display: block;
      text-align: left;
      cursor: pointer;
      margin: 0;
      width: 100%;
      align-self: center;

      &:after {
        position: absolute;
        display: inline-block;
        content: "";
        background: url(/cms/images/iconmonstr-arrow-65.svg) no-repeat;
        background-size: cover;
        width: 16px;
        height: 16px;
        right: 6px;
        top: 22%;
      }
    }

    &--open {
      outline: 1px solid $button-border-hover-color;
      outline-offset: 2px;

      &:hover {
        outline: 1px solid $button-border-hover-color;
      }
    }

    &--open &__selected:after {
      transform: rotate(180deg) scaleX(-1);
    }

    &__items {
      display: block;
      position: absolute;
      text-align: left;
      top: 105%;
      left: 0;
      right: 0;
      margin: 0;
      width: 100%;
      background: #fff;
      box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.2);
      z-index: 1;

      &__item {
        padding: 4px;
        cursor: pointer;

        &--error {
          color: $error-color;
        }

        &:hover {
          background: $button-border-hover-color;
          color: #fff;
        }
      }
    }
  }
}