@import '../config';
@import '../cta';

.shop-page {
  padding: 20px;

  &__search {
    display: flex;
    border-top: 1px solid $colour-maroon;

    &__filters {
      flex-basis: 18%;
      padding-top: 6px;
      min-width: 200px;

      &__options {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: sticky;
        top: 0;
        background: $colour-light-grey;
        padding: 6px;

        &__header {
          margin: 10px 4px 20px 4px;
        }

        &__clear {
          display: inline;
          border: none;
          padding: 0;
          margin: 10px 4px 0 0;
          color: $site-link-dark-colour;
          cursor: pointer;
          outline: none;
          align-self: flex-start;
          background-color: $colour-light-grey;

          &:before {
            display: inline-block;
            content: "";
            background: url(/images/cross.svg) no-repeat 0 -2px;
            width: 18px;
            height: 18px;
            vertical-align: middle;
          }
        }

        &__button {
          display: none;
        }

        &__items {
          background: #fff;
          padding: 8px;
          flex-basis: 100%;

          &__item {
            display: flex;
            align-content: center;
            padding: 10px 0;
            border-bottom: 2px solid $colour-light-grey;
            cursor: pointer;

            &:last-of-type {
              border-bottom: none;
            }

            &__indicator {
              position: relative;
              border: 2px solid $colour-maroon;
              border-radius: 5px;
              margin-right: 6px;
              width: 16px;
              height: 16px;
            }

            &--active &__indicator {
              background: $colour-maroon;

              &:after {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                content: "";
                width: 16px;
                height: 16px;
                background: url(/images/tick.svg) no-repeat;
                background-size: cover;
                filter: brightness(100);
              }
            }

            &__label {
              margin-top: auto;
              margin-bottom: auto;
            }
          }
        }
      }
    }

    &__results {
      display: flex;
      flex-direction: column;
      flex-basis: 82%;
      padding: 6px;

      &__empty {
        align-self: center;
        justify-self: center;
        padding: 60px 0;
      }

      &__options {
        position: sticky;
        top: 0;
        display: flex;
        padding-bottom: 6px;
        background: #fff;

        &__sort {
          flex: 1;
          display: flex;
          align-content: center;
          margin-right: auto;

          &__items {
            &--popup {
              display: none;
            }
          }

          &__label {
            align-self: center;
            margin-right: 10px;
          }

          &__button {
            display: none;
          }
        }
      }

      &__show-more {
        @include cta_maroon_solid;
        margin: 30px 0;
        width: 250px;
        align-self: center;
      }
    }
  }
}

@media (max-width: 780px) {
  .shop-page {
    &__search {
      flex-direction: column;
      position: relative;

      &__filters {
        position: absolute;
        top: 0;
        left: 0;
        right: 50%;
        padding-top: 0;
        min-width: unset;

        &__options {
          padding: 0;

          &__header, &__items, &__clear {
            display: none;
          }

          &__button {
            display: block;
            border: 1px solid $colour-maroon;
            border-top: none;
            border-right: none;
            outline: none;
            font-family: $site-font-main;
            font-size: 14px;
            width: 100%;
            padding: 10px;
            background-color: $colour-light-grey;

            &__count {
              color: $colour-turquoise;
              margin-left: 4px;
            }
          }
        }

        &--open &__options {
          &__clear {
            display: block;
            padding: 10px;
            margin: 0;
            width: 100%;
            text-align: right;
            box-shadow: 0 8px 8px 0 rgba(0,0,0,0.2);
          }

          &__items {
            display: block;
            box-shadow: 0 8px 8px 0 rgba(0,0,0,0.2);
          }
        }
      }

      &__results {
        padding-top: 50px;

        &__options {
          position: absolute;
          top: 0;
          left: 50%;
          right: 0;

          &__sort {
            flex-direction: column;

            &__label {
              display: none;
            }

            &__items {
              display: none;

              &--popup {
                padding: 8px;
                box-shadow: 0 8px 8px 0 rgba(0,0,0,0.2);

                &--open {
                  display: block;
                }
              }
            }

            &__button {
              display: block;
              border: 1px solid $colour-maroon;
              border-top: none;
              outline: none;
              font-family: $site-font-main;
              font-size: 14px;
              width: 100%;
              padding: 10px;
              background-color: $colour-light-grey;
            }
          }
        }
      }
    }

    .product-list__item {
      flex-basis: 33%;
      margin: 0 0 0 0.33%;
      min-width: 200px;

      &__image-container {
        max-height: unset;
      }
    }
  }
}

@media (max-width: 500px) {
  .shop-page {
    .product-list__item {
      flex-basis: 100%;
      margin: 0;
    }
  }
}