@import 'config';

.text-banner {
  text-align: center;
  padding: 20px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  line-height: 20px;

  &--notice {
    background-color: $colour-error;
    transition: background-color 1s ease-in;
  }

  &__title {
    font-weight: bold;
    border-right: 1px solid $colour-maroon;
    padding-right: 8px;
  }

  &__caption {
    font-weight: normal;
    padding-left: 8px;
  }
}

@media (max-width: 780px) {
  .text-banner {
    display: flex;
    flex-direction: column;

    &__title {
      border-right: none;
      font-size: 18px;
    }
  }
}