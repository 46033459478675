@import '../config';
@import '../cta';

.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    font-family: $site-font-header;
    font-size: 50px;
    margin: 50px 0;
    align-self: center;
  }

  &__info {
    margin-bottom: 50px;
    align-self: center;
    font-size: 18px;
    padding: 0 50px;
  }

  &__contacts {
    display: flex;
    justify-content: center;
    font-size: 18px;
    margin-bottom: 50px;
    width: 100%;
    max-width: 1000px;

    &__tel, &__email, &__whatsapp {
      text-decoration: none;
      color: $site-font-colour-dark;
      padding: 10px 0;
      border-right: 1px solid $colour-maroon;
      white-space: nowrap;
    }

    &__whatsapp {
      padding-right: 2%;
      &::before {
        display: inline-block;
        content: "";
        background: url(/images/whatsapp_logo_with_effect.svg);
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
    }

    &__tel {
      padding: 10px 2%;
      &::before {
        display: inline-block;
        content: "";
        background: url(/images/phone_with_effect.svg);
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
    }

    &__email {
      padding-left: 2%;
      border-right: none;
      &::before {
        display: inline-block;
        content: "";
        background: url(/images/email_with_effect.svg);
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    background: $colour-light-grey;
    padding: 50px;
    margin-bottom: 50px;
    max-width: 1000px;
    width: 100%;
    box-sizing: border-box;

    &--sent {
      display: flex;
      flex-direction: column;
      background: $colour-light-grey;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      padding: 200px 0;
      margin-bottom: 50px;
      max-width: 1000px;
      width: 100%;
    }

    &--sent__message {
      margin-top: 16px;
    }

    &__header {
      font-family: $site-font-header;
      font-size: 30px;
      margin-bottom: 20px;
    }

    &__error {
      padding: 10px 4px;
      border-radius: 5px;
      background: $colour-error;
      margin-bottom: 20px;
    }

    &__label {
      margin-bottom: 10px;
      font-family: $site-font-header;

      &--invalid {
        color: #ff0000;
      }
    }

    &__field {
      margin-bottom: 30px;
      border: none;
      padding: 10px 10px;
      font-family: $site-font-main;
      font-size: $site-font-size;
      color: $site-font-colour-dark;
      outline: none;

      &::placeholder {
        font-family: $site-font-header;
        color: $colour-light-grey;
      }

      &--invalid {
        border: 1px solid #ff0000;
      }

      &--multiline {
        min-height: 200px;
        resize: none;
      }
    }

    &__submit {
      @include cta_maroon_solid;
      width: 250px;
      align-self: center;
      margin-top: 20px;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: center;
      height: 50px;
    }
  }
}

@media (max-width: 780px) {
  .contact-page {
    &__contacts {
      flex-direction: column;
      align-items: center;

      &__tel, &__email, &__whatsapp {
        @include cta_maroon_outline;
        min-width: 300px;
        margin-bottom: 20px;
        padding: 13px 10px;
        white-space: nowrap;
      }
    }
  }
}