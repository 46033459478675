@import 'config';

$thickness: 6px;
$smallThickness: 3px;

.spinner {
  position: relative;
  align-self: center;
  justify-self: center;
  height: 100px;
  width: 100px;
  border: $thickness solid #fff;
  border-radius: 100%;
  border-top: $thickness solid $colour-turquoise;
  border-right: $thickness solid $colour-turquoise;
  animation: spin 1s infinite linear;
  margin: 20px 5px;

  &--dark {
    border: $thickness solid $colour-light-grey;
    border-top: $thickness solid $colour-turquoise;
    border-right: $thickness solid $colour-turquoise;
  }

  &--small {
    height: 20px;
    width: 20px;
    border: $smallThickness solid #fff;
    border-top: $smallThickness solid $colour-turquoise;
    border-right: $smallThickness solid $colour-turquoise;
  }

  &--center {
    margin: 20px auto;
  }
}

.spinner--small.spinner--dark {
  border: $smallThickness solid $colour-light-grey;
  border-top: $smallThickness solid $colour-turquoise;
  border-right: $smallThickness solid $colour-turquoise;
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}