@import 'config';
@import 'cta';

.newsletter {
  display: flex;

  &__form {
    padding-top: 40px;
    max-width: 254px;

    &__header {
      font-family: $site-font-header;
      font-weight: 600;
      color: $site-font-colour-light;
      margin-bottom: 16px;
    }

    &__description {
      color: $site-font-colour-light;
    }

    &__error {
      color: #fff;
      margin: 10px 0;
      padding: 4px;
      background: $colour-error;
      border-radius: 5px;
    }

    &__field {
      font-family: $site-font-main;
      font-style: italic;
      font-weight: 400;
      font-size: $site-font-size;
      color: $site-font-colour-light;
      background: none;
      border: none;
      border-bottom: 2px solid #fff;
      margin: 20px 0 0 0;
      padding: 0 0 4px 0;
      width: 100%;
      outline: none;

      &::placeholder {
        font-family: $site-font-main;
        font-style: italic;
        font-weight: 400;
        font-size: $site-font-size;
        color: $site-font-colour-light;
      }
    }

    &__button {
      @include cta_white_outline;
      width: 100%;
      margin-top: 20px;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: center;
      height: 50px;
    }

    &__thanks {
      margin-top: 20px;
      color: #000;
      font-weight: bold;
      text-align: center;
      background: $colour-turquoise;
      padding: 4px;
      border-radius: 5px;
    }
  }
}

@media (max-width: 780px) {
  .newsletter {
    flex-basis: 50%;
    justify-content: center;

    &__form {
      max-width: unset;
    }
  }
}