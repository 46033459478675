@import 'config';

@keyframes slidePanelInFromLeft {
  from {
    left: -$panel-width;
  }

  to {
    left: 0
  }
}

@keyframes slidePanelInFromRight {
  from {
    right: -$panel-width
  }

  to {
    right: 0
  }
}

.cms {

  &__settings {
    display: flex;
    font-family: Arial, serif;
    font-size: 14px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 100;

    &__content {
      color: $model-text-color;
      display: flex;
      flex-direction: column;
      background: $model-background-color;
      overflow-x: hidden;
      box-sizing: border-box;
      overflow-y: scroll;
      padding: 4px;

      &::-webkit-scrollbar {
        display: none;
      }

      &__button {
        width: 50px;
        height: 50px;
        padding: 4px;
        text-align: center;
        border: 1px solid $button-border-color;
        background: transparent;
        color: $model-text-color;
        outline: none;
        margin-bottom: 4px;
        white-space: nowrap;

        &:disabled {
          opacity: 0.2;
          cursor: not-allowed;
        }

        &:not(:disabled):hover {
          border-color: $editable-outline-hover-color;;
        }

        &__icon {
          width: 80%;

          &--invert {
            filter: invert(1);
          }
        }
      }
    }
  }

  &__content {
    flex-basis: 100%;
    font-size: 20px;
  }

  &__editable {
    display: contents;

    &__selector {
      display: none;

      &--hover {
        display: block;
        pointer-events: none;
        position: absolute;
        z-index: 50;
        outline: 2px dashed $editable-outline-hover-color;
      }

      &--active {
        cursor: pointer;
        pointer-events: auto;
        background-color: $editable-outline-hover-color;
        opacity: 0.8;
      }
    }
  }

  &__edit {
    display: flex;
    font-family: Arial, serif;
    font-size: 14px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $ui-blocker-color;
    z-index: 101;
    cursor: not-allowed;

    &--settings &__content {
      left: 0;
      right: unset;
      animation-name: slidePanelInFromLeft;
    }

    &__content {
      cursor: default;
      color: $model-text-color;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: $edit-panel-width;
      background: $model-background-color;
      padding: 20px;
      overflow: hidden;
      overflow-y: scroll;
      animation-name: slidePanelInFromRight;
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;

      &::-webkit-scrollbar {
        display: none;
      }

      &__ui-blocker {
        background: rgba($model-background-color, 0.7);
        top: 0;
        bottom: 0;
        width: $edit-panel-width;
        position: fixed;
      }

      &__header {
        display: flex;
        justify-content: space-between;

        &__title {
          margin-top: 4px;
          color: $model-text-color;
          padding-bottom: 20px;
          font-weight: bold;
          font-size: 16px;
        }

        &__delete-button {
          width: 24px;
          height: 24px;
          padding: 0;
          margin: 0;
        }
      }

      &__footer {
        display: flex;
        color: $model-text-color;
        padding-top: 40px;
        justify-content: flex-end;
      }

      &__error {
        display: flex;
        flex-direction: column;
        background: $error-color;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 5px;

        &__title {
          font-weight: bold;
          margin-bottom: 10px;
        }

        &__message {

        }
      }

      &__field-container {
        display: flex;
        flex-direction: column;
        margin: 6px 0;

        &--checkbox {
          flex-direction: row-reverse;
          justify-content: flex-end;
          padding: 4px;
          align-items: center;
        }

        &__label {
          margin-bottom: 4px;

          &--checkbox {
            margin: 0 0 0 4px;
          }
        }

        &__error {
          color: $error-color;
          margin-bottom: 4px;
          font-size: 12px;
        }

        &__label--checkbox:hover + &__checkbox {
          outline: 1px solid $button-border-color;
          outline-offset: 2px;
        }

        &__checkbox {
          position: relative;
          margin: 0 4px 0 0;
          width: 15px;
          height: 15px;
          background: #fff;
          outline: 1px solid $button-border-color;
          outline-offset: 2px;

          &--error {
            outline: 1px solid $error-color !important;
          }

          &--ticked {
            background: $button-border-hover-color;

            &:after {
              content: "";
              position: absolute;
              top: 2px;
              left: 2px;
              width: 11px;
              height: 11px;
              background: url(/cms/images/iconmonstr-check-mark-1.svg);
              background-size: contain;
              filter: invert(1);
            }
          }
        }

        &__field {
          border: none;
          background: #fff;
          color: $list-text-color;
          font-family: inherit;
          margin: 4px;
          padding: 4px;
          outline: 1px solid $button-border-color;
          outline-offset: 2px;

          &--error {
            outline: 1px solid $error-color !important;
          }

          &:focus {
            outline: 1px solid $button-border-hover-color;
            outline-offset: 2px;
          }

          &--multiline {
            font-family: inherit;
            min-height: 200px;
            resize: none;
          }

          &:disabled {
            background-color: $faint-grey;
            color: #fff;
          }
        }
      }

      &__button {
        padding: 10px 20px;
        color: $button-text-color;
        background: $button-background-color;
        border: 1px solid $button-border-color;
        border-radius: 2px;
        margin-right: 4px;
        min-width: 100px;
        transition: border 0.2s ease-in-out;
        outline: none;
        white-space: nowrap;

        &:disabled {
          opacity: 0.2;
          cursor: not-allowed;
        }

        &:not(:disabled):hover {
          border-color: $button-border-hover-color;
        }

        &:not(:disabled):focus {
          border-color: $button-border-hover-color;
        }

        &--history {
          margin-right: auto;

          &:not(:disabled):focus {
            border-color: $button-border-color;
          }
        }
      }

      &__image-container {
        position: relative;
        display: flex;
        margin: 4px;
        padding: 4px;
        border: 1px solid $button-border-color;
        align-content: center;
        justify-content: center;

        &--error {
          border: 1px solid $error-color !important;
        }

        &:hover {
          border-color: $button-border-hover-color;
        }

        &__empty {
          padding: 10px;
        }

        &--editable {
          cursor: pointer;
        }

        &__image {
          width: 100%;
          object-fit: contain;
        }

        &__remove {
          display: block;
          position: absolute;
          width: 24px;
          height: 24px;
          background: #fff url(/cms/images/iconmonstr-x-mark-4.svg) no-repeat center center;
          border-radius: 100%;
          top: 10px;
          right: 10px;
          cursor: pointer;
          padding: 2px;

          &:hover {
            filter: invert(1);
          }
        }
      }

      &__history {
        display: flex;
        flex-direction: column;
        border: 1px solid $button-border-color;
        padding: 4px;
        margin-top: 10px;

        &__list {
          display: flex;
          flex-direction: column;
          overflow-y: scroll;
          max-height: 300px;

          &__item {
            display: flex;
            cursor: pointer;
            padding: 4px;

            &:nth-child(odd) {
              background-color: $faint-grey;
            }

            &:hover {
              background-color: $button-border-hover-color;
            }

            &__value {
              margin-right: 4px;

              &:last-of-type {
                margin-right: 0;
              }

              &--date {
                align-self: center;
                padding: 4px;
                white-space: nowrap;
              }

              &--changes {
                display: flex;
                flex-wrap: wrap;

                &__diff {
                  border-radius: 5px;
                  margin: 2px;
                  box-sizing: border-box;
                  padding: 4px;

                  &--added {
                    background-color: rgb(141, 213, 70);
                  }

                  &--updated {
                    background-color: rgb(248, 157, 88);
                  }

                  &--removed {
                    background-color: rgb(248, 88, 88);
                  }
                }
              }
            }
          }
        }

        &__empty {
          text-align: center;
          padding: 20px;
        }
      }
    }
  }

  &__confirm-button {
    position: relative;
    background-color: transparent;

    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }

    &__content {
      position: relative;
      display: flex;
      justify-content: center;
      align-content: center;
    }

    &:hover {
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        background-color: $button-border-hover-color;
      }
    }

    &--active {
      &:hover {
        &:before {
          transition: width 2s linear;
          transform-style: initial;
          width: 100%;
        }
      }
    }
  }
}