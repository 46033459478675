@import '../config';
@import '../cta';

.about-page {
  display: flex;
  flex-direction: column;
  padding-top: 40px;

  &__continue {
    @include cta_turquoise_outline;
    width: 250px;
    align-self: center;
    margin: 30px 0;
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__image-container {
      &--hands {
        flex-basis: 45%;
      }

      &--laura {
        position: absolute;
        width: 20%;
        top: 20%;
        left: 35%;
      }
    }

    &__image {
      width: 100%;
    }

    &__title {
      font-family: $site-font-header;
      font-size: 40px;
      margin-bottom: 50px;
      align-self: center;
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      min-height: 450px;
      justify-content: center;
      padding: 50px 100px;

      box-sizing: border-box;
      border-width: 2px;
      border-style: outset;
      border-image-slice: 14;
      border-image-width: 20px;
      border-image-outset: 0px;
      border-image-repeat: stretch stretch;
      border-image-source: url(/images/cta_outline_maroon.svg);

      p {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__content {
      padding: 40px;
      flex-basis: 50%;

      p {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__image-container {
      flex-basis: 60%;
    }

    &__image {
      width: 100%;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    padding: 40px;
    line-height: 24px;

    &__name {
      font-size: 40px;
      font-style: italic;
      margin-top: 40px;
    }
  }
}

@media (max-width: 1000px) {
  .about-page {
    &__header {
      &__content {
        padding: 30px 60px;
        min-height: 350px;
      }

      &__title {
        font-size: 30px;
      }
    }
  }
}

@media (max-width: 780px) {
  .about-page {
    &__header {
      flex-direction: column;

      &__image-container {
        &--laura {
          width: 40%;
          top: 40%;
          left: unset;
          right: 0
        }
      }

      &__content {
        padding-top: 50px;
      }
    }


    &__info {
      flex-direction: column;

      &__image-container {
        &--hands {
          order: 1;
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .about-page {
    &__header {
      &__image-container {
        &--laura {
          top: 35%;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .about-page {
    &__header {
      &__image-container {
        &--laura {
          top: 30%;
        }
      }
    }
  }
}