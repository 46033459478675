@import 'config';

.gallery {
  display: flex;
  justify-content: center;
  align-content: center;
  font-family: Arial, serif;
  font-size: 14px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $ui-blocker-color;
  z-index: 1000;

  &__content {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 80%;
    justify-self: center;
    align-self: center;
    justify-content: space-between;
    align-content: center;
    padding: 20px;
    background: $model-background-color;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &__error {
      background: $error-color;
      padding: 20px;
      color: #fff;
      font-weight: bold;
    }

    &__header {
      position: relative;
      color: $model-text-color;
      padding-bottom: 20px;

      &__title {
        font-weight: bold;
        font-size: 16px;
      }

      &__breadcrumb {
        display: flex;
        color: $sub-header-text-color;
        margin-top: 5px;

        &__item {
          cursor: pointer;

          &:after {
            content: "/";
            cursor: default;
            margin: 0 4px;
          }

          &:first-of-type:before {
            content: "Folder: ";
            cursor: default;
          }

          &:last-of-type:after {
            content: unset;
          }
        }
      }

      &__breadcrumb {
        &--disabled &__item {
          cursor: not-allowed;
        }
      }

      &__sort {
        position: absolute;
        right: 0;
        top: 0;

        &__label {
          margin-right: 4px;
        }

        &__list {
          padding: 4px;
          outline: none;
        }
      }
    }

    &__footer {
      display: flex;
      color: $model-text-color;
      padding-top: 20px;
      justify-content: flex-end;

      &__button {
        padding: 10px 20px;
        color: $button-text-color;
        background: $button-background-color;
        border: 1px solid $button-border-color;
        border-radius: 2px;
        margin-right: 4px;
        min-width: 100px;
        transition: border 0.2s ease-in-out;
        outline: none;

        &--left {
          margin-right: auto;
        }

        &:disabled {
          opacity: 0.2;
          cursor: not-allowed;
        }

        &:not(:disabled):hover {
          border-color: $button-border-hover-color;
        }
      }
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    background: #fff;
    position: relative;
    align-content: flex-start;
    justify-content: flex-start;
    flex-basis: 100%;
    overflow-y: scroll;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    background: $list-background-color;
    color: $list-text-color;

    &--adding &__item:not(&__item--adding) {
      opacity: 0.2;
      filter: grayscale(1);
    }

    &--adding &__item {
      &__content {
        cursor: default;

        &__upload {
          cursor: default;
        }
      }
    }

    &--adding &__item--adding {
      border-color: $list-item-selected-border-color;
    }

    &--selected &__item:not(&__item--image) {
      opacity: 0.2;
      filter: grayscale(1);
    }

    &--selected &__item {
      &__content {
        cursor: default;

        &__upload {
          cursor: default;
        }
      }
    }

    &--selected &__item--image &__item__content {
      cursor: pointer;
    }

    &__item {
      flex-basis: 24.5%;
      margin: 0.25%;
      height: 250px;
      padding: 4px;
      border: 1px $list-item-border-color solid;
      box-sizing: border-box;
      transition: border 0.2s ease-in-out;

      &--selected {
        border: 1px $list-item-selected-border-color solid;
      }

      &__content {
        display: flex;
        position: relative;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-content: center;
        cursor: pointer;

        &__status {
          display: flex;
          position: absolute;
          justify-content: center;
          width: 100%;
          top: 0;

          &__message {
            padding: 10px;
            background: rgba(42, 164, 245, 0.9);
            color: #fff;
            font-weight: bold;
            text-align: center;
            width: 100%;

            &--error {
              background: $error-color;
            }
          }
        }

        &__image {
          width: 100%;
          height: 80%;
          object-fit: scale-down;
        }

        &__folder {
          display: block;
          height: 100px;
          background: url(/cms/images/iconmonstr-folder-2.svg) no-repeat center center;
          background-size: contain;
        }

        &__folder--add {
          display: block;
          height: 100px;
          background: url(/cms/images/iconmonstr-folder-4.svg) no-repeat center center;
          background-size: contain;
        }

        &__back {
          display: block;
          height: 85px;
          background: url(/cms/images/iconmonstr-arrow-28.svg) no-repeat center center;
          transform: rotate(180deg);
          background-size: contain;
          margin: 8px 0;
        }

        &__upload {
          display: block;
          background: url(/cms/images/iconmonstr-picture-8.svg) no-repeat center 37%;
          background-size: 46% 46%;
          color: transparent;
          outline: none;
          position: absolute;
          cursor: pointer;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          width: 100%;

          &::-webkit-file-upload-button {
            visibility: hidden;
          }
        }

        &__header {
          text-align: center;
          padding: 10px;
          font-weight: bold;
        }

        &__label {
          text-align: center;
          padding: 10px;
        }

        &__label--upload {
          position: absolute;
          top: 67%;
          text-align: center;
          left: 0;
          right: 0;
        }

        &__add-folder {
          text-align: center;
          font-family: Arial, serif;
          font-size: 14px;
          border: none;
          padding: 8px;
          outline: none;
          width: 80%;
          align-self: center;
        }
      }
    }
  }
}