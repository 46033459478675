@import 'config';
@import 'cta';

.dropdown {
  position: relative;
  min-width: 200px;

  &__selected {
    @include cta_maroon_outline;
    text-align: left;
    cursor: pointer;
    margin: 0;
    padding: 10px 0 10px 20px;
    width: 100%;

    &:after {
      position: absolute;
      display: inline-block;
      content: "";
      background: url(/images/arrow.svg) no-repeat;
      background-size: cover;
      width: 24px;
      height: 24px;
      right: 10px;
      top: 20%;
      transform: rotate(90deg);
    }
  }

  &--open &__selected:after {
    transform: rotate(90deg) scaleX(-1);
    top: 24%;
  }

  &__items {
    display: block;
    position: absolute;
    text-align: left;
    margin: 0;
    width: 100%;
    background: #fff;
    box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.2);

    &__item {
      padding: 10px 20px;
      cursor: pointer;
      border-bottom: 2px solid $colour-light-grey;

      &:hover {
        background: $colour-maroon;
        color: #fff;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}