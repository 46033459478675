@import '../config';
@import '../cta';

.home-page {
  &__content {
    padding: 50px;
  }

  &__instagram {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }

  &__header {
    font-family: $site-font-header;
    font-weight: 600;
    color: $site-font-colour-dark;
    font-size: 20px;
    margin: 30px 0;
    text-align: center;
  }

  &__instagram__button {
    @include cta_maroon_outline;
    margin: 20px 0;
    width: 250px;
  }
}

@media (max-width: 780px) {
  .home-page {
    &__instagram .picture-nav__item {
      flex-basis: 49%;
      margin: 0 0 0 0.50%;
    }
  }
}

@media (max-width: 430px) {
  .home-page {
    &__instagram .picture-nav__item {
      flex-basis: 100%;
      margin: 0;
    }
  }
}