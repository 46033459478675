@import 'config';

.cms {

  &__image-list {
    display: flex;
    flex-direction: column;

    &__items {
      display: flex;
      flex-wrap: wrap;
      max-height: 312px;
      overflow-y: scroll;
      outline: 1px solid $button-border-color;

      &--error {
        outline: 1px solid $error-color !important;
      }

      &__item {
        position: relative;
        display: flex;
        min-height: 150px;
        background: #fff;
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 49%;
        margin: 0.5%;
        padding: 2px;
        border: 1px solid #000;
        box-sizing: border-box;
        justify-content: center;

        &__image {
          width: 100%;
          display: block;
          align-self: center;
        }

        &--add {
          cursor: pointer;
        }

        &--add &__image {
          width: 60%;
        }

        &__remove {
          display: block;
          position: absolute;
          width: 18px;
          height: 18px;
          background: #fff url(/cms/images/iconmonstr-x-mark-4.svg) no-repeat center center;
          background-size: 18px;
          border-radius: 100%;
          top: 6px;
          right: 6px;
          cursor: pointer;
          padding: 2px;

          &:hover {
            filter: invert(1);
          }
        }

        &:hover &__move {
          display: flex;
        }

        &__move {
          display: none;
          position: absolute;
          top: 6px;
          left: 6px;

          &__back {
            display: block;
            width: 18px;
            height: 18px;
            background: #fff url(/cms/images/iconmonstr-arrow-68.svg) no-repeat center center;
            background-size: 18px;
            border-radius: 100%;
            cursor: pointer;
            padding: 2px;
            margin-right: 2px;

            &:hover {
              filter: invert(1);
            }
          }

          &__next {
            display: block;
            width: 18px;
            height: 18px;
            background: #fff url(/cms/images/iconmonstr-arrow-68.svg) no-repeat center center;
            background-size: 18px;
            border-radius: 100%;
            cursor: pointer;
            padding: 2px;
            transform: rotate(180deg);

            &:hover {
              filter: invert(1);
            }
          }
        }
      }
    }
  }

}