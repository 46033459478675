@import 'config';

.product-list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  &__item {
    flex-basis: (100% / 4) - 0.5%;
    margin: 0 0 0 0.25%;
    min-width: 240px;

    &__link {
      text-decoration: none;
      display: flex;
      flex-direction: column;
    }

    &--no-space {
      flex-basis: (100% / 4);
      line-height: 0;
    }

    &__image-container {
      max-height: 280px;
    }

    &__image {
      width: 100%;
      object-fit: cover;
    }

    &__info {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 10px;
      justify-content: center;
      align-content: center;

      &__title, &__price {
        flex-basis: 100%;
        text-decoration: none;
        font-family: $site-font-main;
        font-style: normal;
        font-weight: 400;
        font-size: $site-link-font-size;
        color: $site-link-dark-colour;
        text-align: center;
        padding: 6px 0;
      }

      &__price {
        font-weight: bold;
      }

      &--with-wishlist {
        justify-content: space-between;
      }

      &--with-wishlist &__title, &--with-wishlist &__price {
        flex-basis: 85%;
        text-align: left;
      }

      &__wishlist {
        display: block;
        align-self: center;
        width: 24px;
        height: 24px;
        border: none;
        background: url(/images/heart_with_effect.svg) no-repeat;
        outline: none;
        cursor: pointer;

        &--active {
          background: url(/images/heart_with_effect_full.svg) no-repeat;
        }
      }
    }
  }

  &--no-wrap {
    flex-wrap: nowrap;
  }

  &--no-wrap &__item {
    min-width: unset;
  }
}

@media (max-width: 780px) {
  .product-list {
    &--no-wrap {
      overflow-x: scroll;
    }

    &--no-wrap &__item {
      width: 240px;
      min-width: 240px;
    }
  }
}