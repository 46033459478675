@import 'config';

.sidebar {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20%;
  min-width: 300px;
  background: #fff;
  padding: 20px;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 100;
  overflow-y: scroll;
  box-shadow: -4px 0px 5px -2px rgba(0,0,0,0.2);
  border-right: 1px solid $colour-light-grey;

  &__header {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 30px;
    flex-basis: 100%;
  }

  &__close {
    background-size: cover;
    background: url(/images/cross.svg) no-repeat center center;
    width: 48px;
    height: 48px;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    margin: 0 0 0 auto;
  }

  &__content {
    flex-basis: 100%;
    font-size: 20px;

    p {
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 780px) {
  .sidebar {
    left: 0;
    width: unset;
    box-shadow: unset;
    border-right: unset;
  }
}