@import 'reset';
@import 'config';

html, body {
  background-color: $site-bg-colour;
  font-family: $site-font-main;
  font-style: normal;
  font-weight: 400;
  font-size: $site-font-size;
  color: $site-font-colour-dark;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.app {
  position: relative;
  width: 100%;
  max-width: 1256px;
  margin: 0 auto;
}

img { overflow: hidden }